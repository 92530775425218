import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from "../actions";

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from "./actions";

import { adminRoot } from "../../constants/defaultValues";
import { setCurrentUser } from "../../helpers/Utils";
import {
  loginRequest,
  loginWithGoogle,
  registerRequest,
} from "../../network/api";

export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (
  email,
  password,
  type = "loginWithPass"
) => {
  let res;
  if (type === "googleAuth") {
    res = await loginWithGoogle({ email, tokenId: password })
      .then((user) => user)
      .catch((error) => error);
  }
  if (type === "loginWithPass") {
    res = await loginRequest(email, password)
      .then((user) => user)
      .catch((error) => error);
  }
  return res;
};

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history, type } = payload;
  try {
    const loginUser = yield call(
      loginWithEmailPasswordAsync,
      email,
      password,
      type
    );
    console.log(loginUser);
    if (loginUser && loginUser.success) {
      // const item = { uid: loginUser.user.uid, ...currentUser };
      setCurrentUser(loginUser);
      yield put(loginUserSuccess(loginUser));
      history.push({
        pathname: adminRoot,
        state: { firstUser: loginUser.is_firstUser },
      });
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error.response.message));
  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (user) => {
  let res = "";
  res = await registerRequest(user)
    .then((item) => {
      return item;
    })
    .catch((err) => {
      return err;
    });
  return res;
};

function* registerWithEmailPassword({ payload }) {
  const { user } = payload;
  const { history } = payload;
  try {
    const registerUser = yield call(registerWithEmailPasswordAsync, user);
    if (registerUser.data) {
      if (registerUser.data.success) {
        yield put(registerUserSuccess(registerUser.data.message));
        setCurrentUser(registerUser.data);
        // yield put(loginUserSuccess(registerUser.data));
        history.push({
          pathname: adminRoot,
          state: { firstUser: registerUser.data.is_firstUser },
        });
      } else {
        yield put(registerUserError(registerUser.data.message));
      }
    }
    if (registerUser.message) {
      yield put(registerUserError(registerUser));
    }
  } catch (error) {
    console.log(error);
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async () => {
  // await auth
  //   .signOut()
  //   .then((user) => user)
  //   .catch((error) => error);
  // history.push(adminRoot);
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async () => {
  // eslint-disable-next-line no-return-await
  // return await auth
  //   .sendPasswordResetEmail(email)
  //   .then((user) => user)
  //   .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess("success"));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async () => {
  // eslint-disable-next-line no-return-await
  // return await auth
  //   .confirmPasswordReset(resetPasswordCode, newPassword)
  //   .then((user) => user)
  //   .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess("success"));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
