export const UserRole = {
  Admin: "Admin",
  Editor: 1,
};

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-sub-hidden";

export const subHiddenBreakpoint = 1340;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English", direction: "ltr" },
  { id: "ja", name: "日本語", direction: "ltr" },
];

export const currentUser = {
  id: 1,
  title: "Sarah Kortney",
  img: "/assets/img/profiles/l-1.jpg",
  date: "Last seen today 15:24",
  role: UserRole.Admin,
};

export const adminRoot = "/";
export const buyUrl = "https://1.envato.market/k4z0";
export const searchPath = `${adminRoot}/pages/miscellaneous/search`;
export const servicePath = "https://api.coloredstrategies.com";

export const themeColorStorageKey = "__theme_selected_color";
export const isMultiColorActive = true;
export const defaultColor = "light.purplemonster";
export const isDarkSwitchActive = true;
export const defaultDirection = "ltr";
export const themeRadiusStorageKey = "__theme_radius";
export const isAuthGuardActive = true;
export const colors = [
  "bluenavy",
  "blueyale",
  "blueolympic",
  "greenmoss",
  "greenlime",
  "purplemonster",
  "orangecarrot",
  "redruby",
  "yellowgranola",
  "greysteel",
];

export const centerContent = "d-flex align-items-center justify-content-center";
export const buttonStyleDefault = `${centerContent} font-weight-bold px-3 py-2`;
