import { QueryClient } from "react-query";

const {
  REACT_APP_BASE_URL,
  REACT_APP_CAT_URL,
  REACT_APP_IMG_URL,
  REACT_APP_PRO_URL,
  REACT_APP_GOOGLE_CLIENT_ID,
} = process.env;
export {
  REACT_APP_BASE_URL,
  REACT_APP_CAT_URL,
  REACT_APP_IMG_URL,
  REACT_APP_PRO_URL,
  REACT_APP_GOOGLE_CLIENT_ID,
};

// REACT QUERY CONFIG

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});
