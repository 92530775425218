/* eslint-disable */
import axios from "axios";
import { getCurrentUser, setCurrentUser } from "../helpers/Utils";
import { REACT_APP_BASE_URL } from "../config";
import NotificationManager from "../components/common/react-notifications/NotificationManager";
export const { user_id, token, company } = getCurrentUser() || "";
export let companyId1 = localStorage.getItem("company");
let companyId = localStorage.getItem("company");

export const isLoggedIn = () => localStorage.getItem("token");
export const userType = () => localStorage.getItem("usertype");
export const menus = () => localStorage.getItem("menu");

function handlerError(error, callback) {
  if (error.message == "Network Error") {
    // location.href = "/server";
    NotificationManager.error(
      "There is a problem while connecting to server",
      "Network Error"
    );
  }
  if (error.response == undefined) {
    console.log();
  } else if (error.response.status == 500) {
    // location.href = "/server";
  } else if (error.response.status == 401) {
    setCurrentUser();
  } else {
    callback &&
      callback({
        status: "error",
        response: error,
      });
  }
}

const alertError = (err) => {
  if (err && err.response)
    return NotificationManager.error(
      err.response.data.message,
      err.response.statusText
    );

  return NotificationManager.error(
    "Something went wrong. Refreshing the page and trying again might help.",
    "Uh oh!"
  );
};
const alertResponse = (res) => {
  NotificationManager.success(res.data.message, res.statusText);
};

// For Authorization

// Request handler
const request = ({
  url,
  extUrl, //only for 3rd party apis
  body = {},
  isFormData = false,
  query,
  requestType = "GET",
}) => {
  const { token } = getCurrentUser() || "";
  //Create FormData instance
  const rowBody = new FormData();
  if (isFormData) {
    //Create a Payload
    Object.keys(body).map((key) => {
      rowBody.append(key, body[key]);
    });
  }
  return axios({
    url: extUrl ? extUrl : REACT_APP_BASE_URL + url,
    params: { ...query },
    data: isFormData ? rowBody : body,
    method: requestType,
    ...(token && {
      headers: {
        Authorization: "Token " + token,
      },
    }),
  });
};

export const requestFromServer = async ({
  url,
  extUrl, //only for 3rd party apis
  body = {},
  query,
  isFormData,
  requestType,
  skipNotifier = false,
}) => {
  try {
    const res = await request({
      extUrl,
      url,
      body,
      query,
      requestType,
      isFormData,
    });
    // notify response message only if request type is not GET
    if (requestType && !skipNotifier) alertResponse(res);
    return Promise.resolve(res.data);
  } catch (err) {
    alertError(err);
    return Promise.reject(err);
  }
};

export const loginRequest = (email, password) =>
  requestFromServer({
    url: "/brand_outlet/login/",
    body: {
      username: email,
      password: password,
    },
    requestType: "POST",
  });

export const registerRequest = (user) =>
  request({
    url: "/brand_outlet/google/signup/",
    body: {
      ...user,
    },
    requestType: "POST",
  });

export const addProductAPI = (payload) =>
  request({
    url: "/product/createupdate_data/product/",
    body: payload,
    isFormData: true,
    requestType: "POST",
  });

export const deleteProductAPI = (id) =>
  request({
    url: "/product/delete/product/",
    body: { id: id.toString() },
    requestType: "POST",
  });

export const AddCouponAPI = (payload) =>
  request({
    url: "/discount/coupon/create_update/",
    body: payload,
    isFormData: true,
    requestType: "POST",
  });

export const outletHideStatusAPI = (payload) =>
  request({
    url: "/outlet/hide/outlet/",
    body: { ...payload },
    requestType: "POST",
  });

export const managerHideStatusAPI = (payload) =>
  request({
    url: "/manager/hide/profile/",
    body: { ...payload },
    requestType: "POST",
  });

export const logoutAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/brand_outlet/logout/`;
  axios
    .post(
      url,
      {},
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      localStorage.removeItem("token");
      localStorage.removeItem("company");
      localStorage.removeItem("companyName");
      localStorage.removeItem("usertype");
      localStorage.removeItem("logo");
      localStorage.removeItem("__theme_color");
      localStorage.removeItem("menu");

      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const APIKeyGenerate = (callback) => {
  let url = `${REACT_APP_BASE_URL}/brand_outlet/api/generation/`;
  axios
    .post(
      url,
      {},
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};
export const changepasswordAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/brand_outlet/ChangePassword/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};
// For Configuration

export const listCompanyAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/listing_data/company/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const addProfileAPI = (
  formdata,
  address,
  website,
  support_person,
  support_person_email_id,
  support_person_mobileno,
  owner_name,
  owner_email,
  owner_phone,
  bannerFileData,
  facebbokUrl,
  instagramUrl,
  callback
) => {
  let url = `${REACT_APP_BASE_URL}/brandprofile/updation/`;
  const formData = new FormData();
  formData.append("company_logo", formdata);
  formData.append("company_landing_imge", bannerFileData);
  formData.append("address", address);
  formData.append("website", website);
  formData.append("support_person", support_person);
  formData.append("support_person_email_id", support_person_email_id);
  formData.append("support_person_mobileno", support_person_mobileno);
  formData.append("owner_name", owner_name);
  formData.append("owner_email", owner_email);
  formData.append("owner_phone", owner_phone);
  formData.append("facebook", facebbokUrl);
  formData.append("instagram", instagramUrl);

  axios
    .post(url, formData, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listActiveFoodTypeAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/Activelisting/FoodType/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};
export const listActiveTagAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/filterlisting/ActiveTags/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listActiveCategoriesAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/Activelisting/Category/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listActiveOutletAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/Activelisting/Outlet/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listActiveStaffAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/staff/list/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listStaffReportAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/staff/report/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};
export const listCityAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/listing_data/City/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};
export const listCountyAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/listing_data/Country/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listCityWiseAreaAPI = (cityId, callback) => {
  let url = `${REACT_APP_BASE_URL}/product/configuration_data/citywise_area_data/`;
  axios
    .post(
      url,
      { id: cityId.toString() },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listActiveVariantAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/Activelisting/variant/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listActiveAddonGroupAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/Activelisting/AddonDetails/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listCatWiseOutletAPI = (catId, callback) => {
  let url = `${REACT_APP_BASE_URL}/product/configuration_data/catwise_outlet_data/`;
  axios
    .post(
      url,
      { cat_id: catId.toString() },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listCatWiseSubcategoryAPI = (catId, callback) => {
  let url = `${REACT_APP_BASE_URL}/product/configuration_data/catwise_subcat_data/`;
  axios
    .post(
      url,
      { cat_id: catId.toString() },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listActiveProductAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/filterlisting/product/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listActiveCustomerAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/customer/Active/listing/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

// For Categories
export const listCategoriesAPI = (status) =>
  request({
    url: "/product/configuration_data/catagory_data/",
    body: { status: status },
    requestType: "POST",
  });

export const editCategoryAPI = (id, callback) => {
  let url = `${REACT_APP_BASE_URL}/product/retrieval_data/category/`;
  axios
    .post(
      url,
      { id: id },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const changeCategoryTypeStatusAPI = (id, status, callback) => {
  let url = `${REACT_APP_BASE_URL}/product/action/Category/`;
  axios
    .post(
      url,
      { id: id, active_status: status },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

// For Outlets

export const listoutletAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/outlet/configuration_data/outlet_data/`;
  axios
    .post(
      url,
      { company_auth_id: companyId },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const changeOutletStatusAPI = (id, status, callback) => {
  let url = `${REACT_APP_BASE_URL}/outlet/action/Outlet/`;
  axios
    .post(
      url,
      { id: id, active_status: status },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

// For Food Type

export const listFoodTypeAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/listing_data/FoodType/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

// For Variants

export const listVariantAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/listing_data/variant/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const getVariantAPI = (id, callback) => {
  let url = `${REACT_APP_BASE_URL}/product/retrieval_data/variant/`;
  axios
    .post(
      url,
      { id: id },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const addVariantAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/product/createupdate_data/variant/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const changeVariantStatusAPI = (id, status, callback) => {
  let url = `${REACT_APP_BASE_URL}/product/action/variant/`;
  axios
    .post(
      url,
      { id: id, active_status: status },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

// For AddonGroup

export const listAddonGroupAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/listing_data/AddonDetails/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const addAddonGroupAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/product/createupdate_data/addons/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const editAddonGroupAPI = (id, callback) => {
  let url = `${REACT_APP_BASE_URL}/product/retrieval_data/AddonDetails/`;
  axios
    .post(
      url,
      { id: id.toString() },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const updateAddonAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/update_data/addons/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const editAddonsAPI = (id, callback) => {
  let url = `${REACT_APP_BASE_URL}/product/retrieval_data/addon/`;
  axios
    .post(
      url,
      { id: id.toString() },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const getAddOnsAPI = (id, callback) => {
  let url = `${REACT_APP_BASE_URL}/product/addons/associate/`;
  axios
    .post(
      url,
      { id: id.toString() },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

// For SubCategory

export const listSubcategoryAPI = (cid, callback) => {
  let url = `${REACT_APP_BASE_URL}/listing_data/SubCategory/?id=${cid}`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const addSubcategoryAPI = (catId, subcategory, callback) => {
  let url = `${REACT_APP_BASE_URL}/product/create_data/subcatagory/`;
  const formData = new FormData();
  formData.append("category", catId);
  let names = [];
  let priority = [];
  let j = 0;
  for (let i = 0; i < subcategory.length; i++) {
    names.push(subcategory[i].name);
    priority.push(subcategory[i].priority);
    formData.append("description" + j, subcategory[i].subcategory_desc);
    formData.append("image" + j, subcategory[i].image);
    j = j + 1;
  }
  formData.append("name", JSON.stringify(names));
  formData.append("priority", JSON.stringify(priority));
  axios
    .post(url, formData, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const editSubCategoryAPI = (id, callback) => {
  let url = `${REACT_APP_BASE_URL}/product/retrieval_data/subcategory/`;
  axios
    .post(
      url,
      { id: id },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const changeSubcategoryStatusAPI = (id, status, callback) => {
  let url = `${REACT_APP_BASE_URL}/product/action/subcategory/`;
  axios
    .post(
      url,
      { id: id, active_status: status },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

// For Product

export const listProductAPI = (queryKey) => {
  const {
    queryKey: [_key, query],
  } = queryKey;
  return requestFromServer({
    url: "/product/listing_data/product/",
    body: query,
    requestType: "POST",
  });
};

export const getProductAPI = (payload) => {
  return request({
    url: "/product/retrieval_data/product/",
    body: payload,
    requestType: "POST",
  });
};
export const changeProductStatusAPI = (payload) =>
  request({
    url: "/product/action/product/",
    body: payload,
    requestType: "POST",
  });

// For Add On

export const listAddonAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/listing_data/AddonDetails/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const addAddonAPI = (id, associated_addons, identifier, callback) => {
  let url = `${REACT_APP_BASE_URL}/product/createupdate_data/addonassociation/`;
  axios
    .post(
      url,
      {
        id: id ? id.toString() : null,
        associated_addons: associated_addons,
        identifier: identifier,
      },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const changeAddonTypeStatusAPI = (id, status, callback) => {
  let url = `${REACT_APP_BASE_URL}/product/action/AddonDetails/`;
  axios
    .post(
      url,
      { id: id, active_status: status },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const changeAddonsStatusAPI = (id, status, callback) => {
  let url = `${REACT_APP_BASE_URL}/product/action/addons/`;
  axios
    .post(
      url,
      { id: id, active_status: status },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listAssociateVariantAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/listing_data/variant/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

// for Coupons

export const listCouponAPI = (status, callback) => {
  let url = `${REACT_APP_BASE_URL}/discount/coupon/listing/`;
  axios
    .post(
      url,
      { company_auth_id: companyId, status: status },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const getCouponAPI = (id, callback) => {
  let url = `${REACT_APP_BASE_URL}/discount/coupon/retrieve/`;
  axios
    .post(
      url,
      { id: id.toString() },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const changeCouponStatusAPI = (id, status, callback) => {
  let url = `${REACT_APP_BASE_URL}/discount/coupon/action/`;
  axios
    .post(
      url,
      { id: id, active_status: status },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listCouponHistoryAPI = (callback) => {
  const usertype = userType();
  let url = "";
  if (usertype == "is_outlet") {
    url = `${REACT_APP_BASE_URL}/history/outlet/couponHistory/`;
  }
  if (usertype == "is_brand" || usertype == "is_cashier") {
    url = `${REACT_APP_BASE_URL}/history/brand/couponHistory/`;
  }
  axios
    .post(
      url,
      {},
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

// for Quantity Based Combos

export const AddQtyComboAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/discount/quantitycombo/create_update/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listQtyComboAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/discount/QuantityCombo/listing/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const getQtyComboAPI = (id, callback) => {
  let url = `${REACT_APP_BASE_URL}/discount/QuantityCombo/retrieve/`;
  axios
    .post(
      url,
      { id: id.toString() },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const changeQtyComboStatusAPI = (id, status, callback) => {
  let url = `${REACT_APP_BASE_URL}/discount/QuantityCombo/action/`;
  axios
    .post(
      url,
      { id: id, active_status: status },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

// For Percentage Combo

export const AddPercentComboAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/discount/percentcombo/create_update/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listPercentAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/discount/PercentCombo/listing/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const getPercentComboAPI = (id, callback) => {
  let url = `${REACT_APP_BASE_URL}/discount/PercentCombo/retrieve/`;
  axios
    .post(
      url,
      { id: id.toString() },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const changePercentComboStatusAPI = (id, status, callback) => {
  let url = `${REACT_APP_BASE_URL}/discount/PercentCombo/action/`;
  axios
    .post(
      url,
      { id: id, active_status: status },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

// For Customer

export const listCustomerAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/customer/All/listing/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

// For Orders

export const listOrdersAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/ordermgnt/Order/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

// For Delivery Boy

export const addOutletDeliveryBoyAPI = (
  id,
  name,
  email,
  mobile,
  address,
  fileData,
  callback
) => {
  const formData = new FormData();
  if (id) {
    formData.append("id", id);
  }
  formData.append("profile_pic", fileData);
  formData.append("name", name);
  formData.append("mobile", mobile);
  formData.append("email", email);
  formData.append("address", address);
  let url = `${REACT_APP_BASE_URL}/outlet/deliveryboy/registration/`;
  axios
    .post(url, formData, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};
// For Dashboard

export const listDashboardCardsAPI = (callback, order) => {
  let url = `${REACT_APP_BASE_URL}/dashboard/brand/home/`;
  axios
    .post(
      url,
      { order: order },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};
// For Order Processing

export const listOrderProcessingAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/outlet/orderlisting/`;
  axios
    .post(
      url,
      {},
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const changeOrderProcessingStatusAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/outlet/orderStatuschange/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const retriveOrderProcessingAPI = (id, callback) => {
  let url = `${REACT_APP_BASE_URL}/outlet/retrievalOrder/`;
  axios
    .post(
      url,
      { id: id.toString() },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

// For Outlet Dashboard

export const listOutletDashboardAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/dashboard/outlet/home/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listCustomerDataAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/history/outlet/customerHistory/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

// For Outlet Setting

export const listOutletSettingAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/outlet/retrieve/outlet/`;
  axios
    .post(
      url,
      {},
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

// For Notification

export const listNotificationCountAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/notification/ordernotification/count/`;
  axios
    .post(
      url,
      {},
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listNotificationAPI = (callback) => {
  let url = "";
  if (localStorage.getItem("usertype") == "is_outlet") {
    url = `${REACT_APP_BASE_URL}/notification/ordernotification/all/`;
  } else {
    url = `${REACT_APP_BASE_URL}/notification/all/`;
  }
  axios
    .post(
      url,
      {},
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const notificationSeenAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/notification/ordernotification/seen/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

//For Outlet On Off

export const OutletOnOffAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/outlet/onoff/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const getOutletOnOffAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/outlet/is_open/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};
// For Product availability

export const listOutletProductAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/outlet/listing/product`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};
export const productAvailibiltyAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/outlet/availability/product/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listOutletCategoryAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/outlet/listing/category/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};
export const categoryAvailibiltyAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/outlet/availability/Category/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listBrandOutletAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/outletmgmt/OutletListing/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listBrandCategoriesAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/outletmgmt/Category/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listBrandProductsAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/outletmgmt/Product/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const brandCategoryAvailibiltyAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/outletmgmt/Categoryavail/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const brandProductAvailibiltyAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/outletmgmt/Productavail/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const brandOutletStatusAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/outletmgmt/IsOpen/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const getOrderAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/ordermgnt/Retrieval/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const manageOrderProcessingAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/ordermgnt/ChangeStatus/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const soundStatusAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/sound/status/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const ChangeSoundModeAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/sound/ChangeStatus/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const brandNotificationCountAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/notification/ordercount/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const notificationBrandSeenAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/notification/seen/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listActiveStatusProductAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/Activelisting/Product/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listActiveIngredientsAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/Activelisting/Ingredient/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listPaymentAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/configuration/payment/setting/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const addPaymentAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/configuration/payment/edit/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const changePaymentStatusAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/configuration/payment/action/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const changecustomerStatusAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/customer/Action/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const getCustomerAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/customer/OrderAnalysis/retrieve/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const getTagAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/customer/tag/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listDeliveryAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/configuration/deliverycharge/setting/`;

  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const addDeliveryAPI = (
  tab,
  pType,
  deliveryCharge,
  packageCharge,
  deliveryId,
  isTax,
  taxArray,
  symbol,
  keyId,
  keySecret,
  accentColor,
  textColor,
  secondaryColor,
  themeId,
  paymentId,
  googleSnippet,
  uid,
  googleId,
  callback
) => {
  let url = `${REACT_APP_BASE_URL}/configuration/deliverycharge/edit/`;
  const formData = new FormData();

  formData.append("tab", tab);
  formData.append("delivery_id", deliveryId);
  formData.append("price_type", pType);
  formData.append("delivery_charge", deliveryCharge);
  formData.append("package_charge", packageCharge);
  formData.append("is_tax", isTax);
  formData.append("tax", JSON.stringify(taxArray));
  formData.append("keyid", keyId);
  formData.append("keySecret", keySecret);
  formData.append("symbol", symbol);
  formData.append("payment_id", paymentId);
  formData.append("accent_color", accentColor);
  formData.append("textColor", textColor);
  formData.append("secondaryColor", secondaryColor);
  formData.append("theme_id", themeId);
  formData.append("analytics_snippets", googleSnippet);
  formData.append("u_id", uid);
  formData.append("google_id", googleId);
  axios
    .post(url, formData, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const changeDeliveryStatusAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/configuration/deliverycharge/action/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};
export const listOrderHistoryAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/customer/OrderHistory/listing/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listDiscountAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/offer/product/list/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const addDiscountAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/offer/product/save/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};
export const changeDiscountStatusAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/offer/product/action/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const addTagsAPI = (id, tag, fileData, callback) => {
  const formData = new FormData();
  if (id) {
    formData.append("id", id);
  }
  formData.append("tag_image", fileData);
  formData.append("tag_name", tag);
  let url = `${REACT_APP_BASE_URL}/tag/createupdate_data/`;
  axios
    .post(url, formData, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};
export const listTagsAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/tag/list/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const retrieveTagAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/tag/retrieve/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const tagStatusAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/tag/action/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const addUserTypeAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/manager/createupdate/usertype/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listUserTypeAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/manager/listing/usertype/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const getUserTypeAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/manager/retrieval/usertype/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const changeUserTypeStatusAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/manager/action/usertype/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listActiveUserTypeAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/manager/activelisting/usertype/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listManagersAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/manager/listing/profile/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const addManagerAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/manager/createupdate/profile/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const getManagerAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/manager/retrieval/profile/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const changeManagerStatusAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/manager/action/profile/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listParentModuleAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/manager/listing/MainRoute/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listSubModuleAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/manager/listing/Route/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listSubSubModuleAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/manager/listing/SubRoute/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listActiveCouponAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/emailsetting/coupon/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const orderEmailListAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/emailsetting/setting/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const AddOrderEmailDetailAPI = (
  title,
  content,
  subContent,
  discountContent,
  coupon,
  fileData,
  id,
  callback
) => {
  const formData = new FormData();
  formData.append("id", id);
  formData.append("title", title);
  formData.append("content", content);
  formData.append("dis_content", discountContent);
  formData.append("thank", subContent);
  formData.append("image", fileData);
  formData.append("coupon", coupon);

  let url = `${REACT_APP_BASE_URL}/emailsetting/edit/`;
  axios
    .post(url, formData, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const changeOrderEmailStatusAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/emailsetting/action/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const uploadExcelFormatAPI = (file, callback) => {
  const formData = new FormData();
  formData.append("image", file);
  let url = `${REACT_APP_BASE_URL}/customer/import/`;
  axios
    .post(url, formData, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const uploadExcelCategoryAPI = (file, callback) => {
  const formData = new FormData();
  formData.append("image", file);
  let url = `${REACT_APP_BASE_URL}/category/import/`;
  axios
    .post(url, formData, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const uploadExcelProductAPI = (file, callback) => {
  const formData = new FormData();
  formData.append("image", file);
  let url = `${REACT_APP_BASE_URL}/product/import/`;
  axios
    .post(url, formData, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const AddDiscountAPI = (payload) =>
  request({
    url: "/discount/create_update/",
    body: payload,
    isFormData: true,
    requestType: "POST",
  });

export const listDiscountPercAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/discount/list/`;
  axios
    .get(url, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
          responseType: "blob",
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const getDiscountAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/discount/retrieve/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const changeDiscountPercnStatusAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/discount/action/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listReasonsAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/discount/reason/list/`;
  axios
    .get(url, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
          responseType: "blob",
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const AddReasonAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/discount/reason/createupdate_data/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const getReasonAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/discount/reason/retrieve/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const changeReasonStatusAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/discount/reason/action/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listCatWiseProductAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/filterlisting/multiplecategory/product/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listProReportAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/product/report/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listAddonReportAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/addon/report/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listPerrmissionAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/UserRoll/allmenu/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const AddPermissionAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/UserRoll/savepermission/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listBillPerrmissionAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/UserRoll/bill/allmenu/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const AddBillPermissionAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/UserRoll/bill/savepermission/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listPaymentReportAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/ordermgnt/payment/report/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listReportOutletAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/ordermgnt/outlet/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listActiveTaxAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/configuration/activetax/listing/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const orderAllStatusAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/order/allstatus/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listOrdersReterieveAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/order/retrieve/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listOrderEditAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/order/edit/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const downloadRatingCSVAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/ordermgnt/ratingcsv/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
      responseType: "application/vnd.ms-excel",
    })
    .then((response) => {
      const type = response.headers["content-type"];
      const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Rating_Report.xlsx";
      link.click();
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const uploadRatingExcelFormatAPI = (file, callback) => {
  const formData = new FormData();
  formData.append("rating_xls", file);
  let url = `${REACT_APP_BASE_URL}/ordermgnt/ratingcsv/upload/`;
  axios
    .post(url, formData, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const lstOutletLogsAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/ordermgnt/log/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const addCategoryAPI = (
  id,
  name,
  code,
  priority,
  formdata,
  category_desc,
  companyId1,
  callback
) => {
  // let url = `${REACT_APP_BASE_URL}/createupdate_data/catagory/`;
  let url = `${REACT_APP_BASE_URL}/product/category/create/`;
  const formData = new FormData();
  if (id == "add") {
    formData.append("category_image", formdata);
    formData.append("category_name", name);
    formData.append("category_code", code);
    formData.append("priority", priority);
    formData.append("description", category_desc);
    formData.append("company_auth_id", companyId1);
  } else {
    formData.append("category_image", formdata);
    formData.append("category_name", name);
    formData.append("category_code", code);
    formData.append("priority", priority);
    formData.append("description", category_desc);
    formData.append("id", id);
    formData.append("company_auth_id", companyId1);
  }

  axios
    .post(url, formData, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const addOutletAPI = (
  outletID,
  Outletname,
  address,
  longitude,
  latitude,
  country,
  state,
  location,
  radius,
  acceptance,
  processing,
  dispatch,
  img,
  deliveryTime,
  ordervalue,
  companyId1,
  city,
  locality,
  timeRange,
  days,
  tab,
  header,
  footer,
  gst,
  sunday,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  paymentMethod,
  deliveryZone,
  outletCity,
  landmark,
  pincode,
  callback
) => {
  let url = `${REACT_APP_BASE_URL}/outlet/brand_outlet/outlet_creation/`;
  const formData = new FormData();
  formData.append("tab", tab);
  formData.append("id", outletID);
  formData.append("outlet_image", img);
  formData.append("Outletname", Outletname);
  formData.append("latitude", latitude);
  formData.append("company_auth_id", companyId1);
  formData.append("longitude", longitude);
  formData.append("address", address);
  formData.append("country", country);
  formData.append("state", state);
  formData.append("no_of_days", days);
  formData.append("location", location);
  formData.append("radius", radius);
  formData.append("acceptance", acceptance);
  formData.append("processing", processing);
  formData.append("dispatch", dispatch);
  formData.append("average_delivery_time", deliveryTime);
  formData.append("min_order_value", ordervalue);
  formData.append("map_city", JSON.stringify(city));
  formData.append("map_locality", JSON.stringify(locality));
  formData.append("time_range", timeRange);
  formData.append("header", header);
  formData.append("footer", footer);
  formData.append("gst", gst);
  formData.append("sunday", JSON.stringify(sunday));
  formData.append("monday", JSON.stringify(monday));
  formData.append("tuesday", JSON.stringify(tuesday));
  formData.append("wednesday", JSON.stringify(wednesday));
  formData.append("thursday", JSON.stringify(thursday));
  formData.append("friday", JSON.stringify(friday));
  formData.append("saturday", JSON.stringify(saturday));
  formData.append("payment_method", paymentMethod);
  formData.append("delivery_zone", JSON.stringify(deliveryZone));
  formData.append("city", outletCity);
  formData.append("pincode", pincode);
  formData.append("landmark", landmark);

  axios
    .post(url, formData, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const updateSubcategoryAPI = (
  id,
  category,
  subcategory_name,
  description,
  formdata,
  priority,
  callback
) => {
  let url = `${REACT_APP_BASE_URL}/product/update_data/subcatagory/`;
  const formData = new FormData();
  formData.append("subcategory_image", formdata);
  formData.append("category", category);
  formData.append("subcategory_name", subcategory_name);
  formData.append("description", description);
  formData.append("priority", priority);
  formData.append("id", id);
  // formData.append("company_auth_id", companyId1);
  axios
    .post(url, formData, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listCountrywiseCurrencyAPI = (countryId, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/get/currency/`;
  axios
    .post(
      url,
      { id: countryId.toString() },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listPaymentMethodAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/payment/list/`;
  axios
    .post(
      url,
      {},
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listAllPaymentMethodAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/payment/alllist/`;
  axios
    .post(
      url,
      {},
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const onlineAllPaymentMethodAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/configuration/onlinepayment/list/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const onlinePaymentActionAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/configuration/onlinepayment/action/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const retrievePaymentMethodAPI = (id, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/payment/retrieve/`;
  axios
    .post(
      url,
      { id: id },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const addPaymentMethodAPI = (
  id,
  country,
  symbol,
  payment_method,
  keyid,
  keySecret,
  wlimit,
  formdata,
  callback
) => {
  let url = `${REACT_APP_BASE_URL}/v2/payment/create_update/`;
  const formData = new FormData();
  if (id == "add") {
    formData.append("country", country);
    formData.append("symbol", symbol);
    formData.append("payment_method", payment_method);
    formData.append("keyid", keyid);
    formData.append("keySecret", keySecret);
    formData.append("word_limit", wlimit);
    formData.append("payment_logo", formdata);
  } else {
    formData.append("country", country);
    formData.append("symbol", symbol);
    formData.append("payment_method", payment_method);
    formData.append("keyid", keyid);
    formData.append("keySecret", keySecret);
    formData.append("word_limit", wlimit);
    formData.append("payment_logo", formdata);
    formData.append("id", id);
  }

  axios
    .post(url, formData, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const addUnitAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/create_update/unit/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};
export const addHomePageAPI = (
  webImage,
  mobileImage,
  carousel1Image,
  carousel2Image,
  healthImage,
  healthText,
  carousal2Text,
  carousal1Text,
  isParmotion,
  images,
  imageUrl,
  callback
) => {
  let url = `${REACT_APP_BASE_URL}/v2/home/create/`;
  const formData = new FormData();
  for (let i = 0; i < images.length; i++) {
    formData.append("image" + i, images[i]);
  }
  formData.append("web_slider", webImage);
  formData.append("mobile_slider", mobileImage);
  formData.append("carousel_text", carousal1Text);
  formData.append("carousel_image", carousel1Image);
  formData.append("carousel_text1", carousal2Text);
  formData.append("carousel_image1", carousel2Image);
  formData.append("health_text", healthText);
  formData.append("health_image", healthImage);
  // formData.append("promotions_image", images);
  formData.append("is_promotions", isParmotion);
  formData.append("promotions_url", imageUrl);
  axios
    .post(url, formData, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listHomePageAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/home/retrieve/`;
  axios
    .post(
      url,
      {},
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const addPagesAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/page/create/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const changePagesStatusAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/page/action/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};
export const listPagesAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/page/list/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listUnitAPI = (id, ing, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/listing/unit/?ingID=${id}&ingType=${ing}`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listStatusUnitAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/listing/status/unit/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const editPagesAPI = (id, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/page/retrieve/${id}`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const editUnitAPI = (id, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/retrieve/unit/`;
  axios
    .post(
      url,
      { id: id },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const addRecipeAPI = (
  id,
  name,
  foodtype,
  ingtype,
  fileData,
  primaryArray,
  outputArray,
  callback
) => {
  let url = `${REACT_APP_BASE_URL}/v2/create_update/primary/`;
  const formData = new FormData();
  if (id == "add") {
    formData.append("name", name);
    formData.append("ingredient_type", ingtype);
    formData.append("food_type", foodtype);
    formData.append("image", fileData);
    formData.append("primary_deatils", JSON.stringify(primaryArray));
    formData.append("output_yield", JSON.stringify(outputArray));
  } else {
    formData.append("name", name);
    formData.append("ingredient_type", ingtype);
    formData.append("food_type", foodtype);
    formData.append("image", fileData);
    formData.append("primary_deatils", JSON.stringify(primaryArray));
    formData.append("output_yield", JSON.stringify(outputArray));
    formData.append("id", id);
  }
  axios
    .post(url, formData, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listRecipeAPI = (status, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/listing/primary/?status=${status}`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const editReceipeAPI = (id, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/retrieve/primary/`;
  axios
    .post(
      url,
      { id: id },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const changeReceipeStatusAPI = (id, status, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/action/primary/`;
  axios
    .post(
      url,
      { id: id, active_status: status },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const changeUnitStatusAPI = (id, status, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/action/unit/`;
  axios
    .post(
      url,
      { id: id, active_status: status },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const changeTaxStatusAPI = (id, status, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/action/tax/`;
  axios
    .post(
      url,
      { id: id, active_status: status },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const changePaymentsStatusAPI = (id, status, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/payment/action/`;
  axios
    .post(
      url,
      { id: id, active_status: status },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const allCatWiseSubcategoryAPI = (catId, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/getsubcategory/`;
  axios
    .post(
      url,
      { cat_id: catId },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const getCategoryAPI = (id, callback) => {
  // let url = `${REACT_APP_BASE_URL}/view_data/category/`;
  let url = `${REACT_APP_BASE_URL}/product/view_data/category/`;
  axios
    .post(
      url,
      { id: id },
      {
        headers: { Authorization: "Token " + token },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const deleteCategoryAPI = (id, callback) => {
  // let url = `${REACT_APP_BASE_URL}/delete/category/`;
  let url = `${REACT_APP_BASE_URL}/product/delete/category/`;
  axios
    .post(
      url,
      { id: id.toString() },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const deleteSubcategoryAPI = (id, callback) => {
  let url = `${REACT_APP_BASE_URL}/product/delete/subcategory/`;
  axios
    .post(
      url,
      { id: id.toString() },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listSecondaryAPIs = (callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/listing/secondarys/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const addTaxAPI = (id, tax_name, tax_percent, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/create_update/tax/`;
  const formData = new FormData();
  if (id == "add") {
    formData.append("tax_name", tax_name);
    formData.append("tax_percent", tax_percent);
  } else {
    formData.append("tax_name", tax_name);
    formData.append("tax_percent", tax_percent);
    formData.append("id", id);
  }
  axios
    .post(url, formData, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listTaxAPI = (status, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/listing/tax/`;
  axios
    .post(
      url,
      { company_auth_id: companyId, status: status },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const editTaxAPI = (id, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/retrieve/tax/`;
  axios
    .post(
      url,
      { id: id },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const addSourceAPI = (id, name, payment, fileData, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/create_update/source/`;
  const formData = new FormData();
  if (id == "add") {
    formData.append("source_name", name);
    formData.append("payment", JSON.stringify(payment));
    formData.append("image", fileData);
  } else {
    formData.append("source_name", name);
    formData.append("payment", JSON.stringify(payment));
    formData.append("image", fileData);
    formData.append("id", id);
  }
  axios
    .post(url, formData, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const changeSourceStatusAPI = (id, status, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/action/source/`;
  axios
    .post(
      url,
      { id: id, active_status: status },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listSourceAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/listing/source/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listEventsAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/listing/event_type/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const editSourceAPI = (id, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/retrieve/source/`;
  axios
    .post(
      url,
      { id: id },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};
export const addEventAPI = (id, name, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/create_update/event_type/`;
  const formData = new FormData();
  if (id == "add") {
    formData.append("event_type", name);
  } else {
    formData.append("event_type", name);
    formData.append("id", id);
  }
  axios
    .post(url, formData, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const reterieveEventTypeAPI = (id, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/retrieve/event_type/`;
  axios
    .post(
      url,
      { id: id },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};
export const changeEventTypeStatusAPI = (id, status, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/action/event_type/`;
  axios
    .post(
      url,
      { id: id, active_status: status },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const deleteEventTypeAPI = (id, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/delete/event_type/`;
  axios
    .post(
      url,
      { id: id.toString() },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listEventTagAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/listing/event_tag/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const addEventTagAPI = (id, name, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/create_update/event_tag/`;
  const formData = new FormData();
  if (id == "add") {
    formData.append("tag_name", name);
  } else {
    formData.append("tag_name", name);
    formData.append("id", id);
  }
  axios
    .post(url, formData, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const reterieveEventTagAPI = (id, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/retrieve/event_tag/`;
  axios
    .post(
      url,
      { id: id },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};
export const changeEventTagStatusAPI = (id, status, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/action/event_tag/`;
  axios
    .post(
      url,
      { id: id, active_status: status },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const deleteEventTagAPI = (id, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/delete/event_tag/`;
  axios
    .post(
      url,
      { id: id.toString() },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

// City Part

export const listCitysAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/listing/city/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const addMenuUploadAPI = (
  id,
  name,
  formdata,
  imageName,
  image,
  callback
) => {
  let url = `${REACT_APP_BASE_URL}/v2/create_update/menu/`;
  const formData = new FormData();
  if (id == "add") {
    formData.append("menu_image", formdata);
    formData.append("new_menu_image", imageName);
    formData.append("old_image", image);
    formData.append("menu_name", name);
  } else {
    formData.append("menu_image", formdata);
    formData.append("new_menu_image", imageName);
    formData.append("old_image", image);
    formData.append("menu_name", name);
    formData.append("id", id);
  }

  axios
    .post(url, formData, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listMenuUploadAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/listing/menu/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const editMenuUploadAPI = (id, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/retrieve/menu/`;
  axios
    .post(
      url,
      { id: id.toString() },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const changeMenuUploadStatusAPI = (id, status, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/action/menu/`;
  axios
    .post(
      url,
      { id: id, active_status: status },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const addLocalityAPI = (id, name, city, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/create_update/locality/`;
  const formData = new FormData();
  if (id == "add") {
    formData.append("area", name);
    formData.append("city", city);
  } else {
    formData.append("area", name);
    formData.append("city", city);
    formData.append("id", id);
  }
  axios
    .post(url, formData, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listLocalityAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/listing/locality/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const changeLocalityStatusAPI = (id, status, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/action/locality/`;
  axios
    .post(
      url,
      { id: id, active_status: status },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const editLocalityAPI = (id, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/retrieve/locality/`;
  axios
    .post(
      url,
      { id: id.toString() },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listCityLocalityAPI = (city, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/city/locality/`;
  axios
    .post(
      url,
      { city: city },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listStatesAPI = (id, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/listing/state/`;
  axios
    .post(
      url,
      { country_id: id },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const reteriveOutletAPI = (id, callback) => {
  let url = `${REACT_APP_BASE_URL}/outlet/retrieval_data/outlet/`;
  axios
    .post(
      url,
      { id: id },
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      }
    )
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const editOutletAPI = (
  id,
  img,
  deliveryTime,
  ordervalue,
  companyId1,
  city,
  locality,
  radius,
  acceptance,
  processing,
  dispatch,
  timeRange,
  days,
  country,
  state,
  tab,
  header,
  footer,
  gst,
  sunday,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  paymentMethod,
  deliverzone,
  outletCity,
  landmark,
  pincode,
  callback
) => {
  let url = `${REACT_APP_BASE_URL}/outlet/brand_outlet/outlet_updation/`;
  const formData = new FormData();
  formData.append("tab", tab);
  formData.append("id", id);
  formData.append("outlet_image", img);
  formData.append("company_auth_id", companyId1);
  formData.append("average_delivery_time", deliveryTime);
  formData.append("min_order_value", ordervalue);
  formData.append("map_city", JSON.stringify(city));
  formData.append("map_locality", JSON.stringify(locality));
  formData.append("time_range", timeRange);
  formData.append("no_of_days", days);
  formData.append("country", country);
  formData.append("state", state);
  formData.append("radius", radius);
  formData.append("acceptance", acceptance);
  formData.append("processing", processing);
  formData.append("dispatch", dispatch);
  formData.append("header", header);
  formData.append("footer", footer);
  formData.append("gst", gst);
  formData.append("sunday", JSON.stringify(sunday));
  formData.append("monday", JSON.stringify(monday));
  formData.append("tuesday", JSON.stringify(tuesday));
  formData.append("wednesday", JSON.stringify(wednesday));
  formData.append("thursday", JSON.stringify(thursday));
  formData.append("friday", JSON.stringify(friday));
  formData.append("saturday", JSON.stringify(saturday));
  formData.append("payment_method", paymentMethod);
  formData.append("delivery_zone", JSON.stringify(deliverzone));
  formData.append("landmark", landmark);
  formData.append("city", outletCity);
  formData.append("pincode", pincode);

  axios
    .post(url, formData, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};
export const filterOrdersAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/order/list/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listContactLessAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/v2/menu/count/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listAllRiderAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/rider/all/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const listRiderReportAPI = (payload, callback) => {
  let url = `${REACT_APP_BASE_URL}/rider/list/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

// For Outlet Dashboard

export const listDashboardEventAPI = (callback) => {
  let url = `${REACT_APP_BASE_URL}/dashboard/brand/event/`;
  axios
    .get(url, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const loginWithGoogle = (query) =>
  requestFromServer({
    url: "/brand_outlet/google/login/",
    body: query,
    requestType: "POST",
  });

export const getCountries = (params) =>
  request({ url: "/v2/brand/country/", requestType: "GET" });
